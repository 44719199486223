import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { chain } from 'lodash';
import { OrderStatus, UserTicketStatus } from 'swingby-shared/dist/enums';
import { Order } from 'swingby-shared/dist/interfaces';
import { IResult } from '../../../types';
import useUser from '../../users/hooks/useUser';
import { graphqlClient } from '../../../lib/graphql';
import { dayjs } from '../../../lib';

interface UseOrders {
  usedOrCancelled: Order[];
  usableOrders: Order[];
}

const QUERY_KEY_ORDERS = ['orders'];

const getOrders = async (): Promise<Order[]> => {
  const { result } = await graphqlClient.request<IResult<Order[]>>(QUERY_ORDERLIST, {
    filter: { _operators: { status: { in: [OrderStatus.Paid, OrderStatus.Cancelled] } } },
  });

  return result;
};

const useOrders = (): UseOrders => {
  const { user } = useUser();

  const { data } = useQuery({ queryKey: QUERY_KEY_ORDERS, queryFn: getOrders, enabled: !!user });

  const usedOrCancelled = useMemo(
    () =>
      chain(data)
        .filter(({ userTickets }) =>
          userTickets.every(({ status }) => [UserTicketStatus.Cancelled, UserTicketStatus.Used].includes(status)),
        )
        .sortBy(({ userTickets, payments }) => userTickets[0]?.enterAt || payments[0]?.refundedAt)
        .reverse()
        .value(),
    [data],
  );

  const usableOrders = useMemo(
    () =>
      chain(data)
        .filter(
          ({ userTickets }) =>
            userTickets.some(({ status }) => status === UserTicketStatus.Paid) &&
            dayjs(userTickets[0].reservedAt).diff(dayjs(), 'day') >= 0,
        )
        .sortBy(({ userTickets }) => userTickets[0].reservedAt)
        .value(),
    [data],
  );

  return {
    usedOrCancelled,
    usableOrders,
  };
};

export default useOrders;

export const QUERY_ORDERLIST = gql`
  query orders($filter: FilterFindManyOrderInput) {
    result: orderFindMany(filter: $filter) {
      id
      uid
      userTickets {
        metaData {
          bandId
          parentPackage
        }
        uid
        reservedAt
        ticket {
          name
          ageGroup
          metaData {
            tag
          }
        }
        status
        enterAt
        pass {
          serialNumber
          isReservationChanged
          registration
        }
      }
      userProducts {
        metaData {
          parentPackage
        }
        id
        uid
        product {
          price
          id
          name
          metaData {
            tag
            packageOriginalPrice
          }
        }
        status
      }
      userCoupons {
        uid
        owner {
          name
        }
        coupon {
          absoluteValidity
          lifeTimeUnit
          lifeTimeValue
          kioskExposureName
          type
        }
        status
      }
      payments {
        refundedAt
      }
      status
      park {
        uid
        name
      }
    }
  }
`;
