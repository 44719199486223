import { useRouter } from 'next/router';
import Image from 'next/image';
import { useEffect, useState, memo } from 'react';
import styled from '@emotion/styled';
import { Badge, IconButton } from '@mui/material';
import home from '../../../public/icon-menu-home-disabled.svg';
import homeActive from '../../../public/icon-menu-home.svg';
import ticket from '../../../public/icon-menu-tickets-disabled.svg';
import ticketActive from '../../../public/icon-menu-tickets.svg';
import play from '../../../public/icon-menu-play-disabled.svg';
import playActive from '../../../public/icon-menu-play.svg';
import menu from '../../../public/icon-menu-my-disabled.svg';
import menuActive from '../../../public/icon-menu-my.svg';
import useOrders from '@/pages/main/hooks/useOrders';

const BottomNav = () => {
  const router = useRouter();
  const [navHref, setNavHref] = useState<string>('');
  const { usableOrders } = useOrders();
  const currentPath = router.pathname;

  useEffect(() => {
    router.prefetch('/ticket/park');
    router.prefetch('/play');
    router.prefetch('/users');
  }, [router]);

  useEffect(() => {
    if (location.pathname === '/') {
      setNavHref('home');
    }
    if (location.href.includes('ticket')) {
      setNavHref('ticket');
    }
    if (location.href.includes('play')) {
      setNavHref('play');
    }
    if (location.href.includes('users')) {
      setNavHref('users');
    }
  }, []);

  return (
    <>
      <NavContainer>
        <div>
          <IconButton onClick={() => router.push('/')}>
            <Image src={navHref === 'home' ? homeActive : home} alt="home" width="20" height="20" />
            <NavigationLabel className={navHref === 'home' ? 'active' : ''}>HOME</NavigationLabel>
          </IconButton>
        </div>

        <div>
          <IconButton onClick={() => router.push('/ticket/park')}>
            <Image src={navHref === 'ticket' ? ticketActive : ticket} alt="tickets" width="20" height="20" />
            <NavigationLabel className={navHref === 'ticket' ? 'active' : ''}>TICKETS</NavigationLabel>
          </IconButton>
        </div>

        <div>
          <IconButton onClick={() => router.push('/play')}>
            <Image src={navHref === 'play' ? playActive : play} alt="play" width="20" height="20" />
            <NavigationLabel className={navHref === 'play' ? 'active' : ''}>PLAY</NavigationLabel>
          </IconButton>
        </div>

        <div>
          <Badge
            invisible={currentPath === '/users' || usableOrders.length === 0}
            badgeContent={usableOrders.length}
            color="error"
            overlap="circular"
            sx={{ marginTop: 1 }}
          >
            <IconButton onClick={() => router.push('/users')}>
              <Image src={navHref === 'users' ? menuActive : menu} alt="my" width="20" height="20" />
              <NavigationLabel className={navHref === 'users' ? 'active' : ''}>MY</NavigationLabel>
            </IconButton>
          </Badge>
        </div>
      </NavContainer>
    </>
  );
};

export default memo(BottomNav);

const NavContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  position: fixed;
  bottom: 0;
  height: 56px;
  text-align: center;
  border-top: 1px solid #0000000a;
  background-color: #ecedee;
  width: 100%;
  max-width: 640px;
  z-index: 20;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      flex-direction: column;
      width: 53px;
    }
  }
`;

const NavigationLabel = styled.span`
  margin-top: 4px;
  font-size: 10px;
  font-family: Montserrat;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.33);

  &.active {
    font-weight: 800;
    color: rgba(0, 0, 0, 1);
  }
`;
